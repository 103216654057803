<template>
  <div>
    <nav class="navbar navbar-expand-md nav-shadow" :class="{'bg-dark': !$root.d}">
        <a class="navbar-brand">
            
      <img v-if='$root.d' @click='goTo("Main")' src="@/assets/logo.png" style="
    width: 200px;" class='link logo-image' alt="" srcset="">
      <!-- <img v-if='$root.d' @click='goTo("Main")' src="@/assets/logo.png" class='link d-lg-none' 
      style='width: 50%;min-width:200px;
    margin: 8px 0px 0px 5px;' alt="" srcset=""> -->

    <img v-if='!$root.d' @click='goTo("Main")' style="
    width: 200px;" src="@/assets/logo-dark.png" class='link logo-image' alt="" srcset="">
      <!-- <img v-if='!$root.d' @click='goTo("Main")' src="@/assets/logo-dark.png" class='link d-lg-none' style='width: 50%;min-width:200px;
    margin: 8px 0px 0px 5px;' alt="" srcset=""> -->
        </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
        <li class="nav-item px-lg-3 btn btn-link" @click='goTo("How")'>
          <a class="nav-link n-item" >
            How It Works
          </a>
      </li>
      <li class="nav-item px-lg-3 btn btn-link" @click='goTo("Dashboard")' v-if="user">
          <a class="nav-link n-item" >
            Dashboard
          </a>
      </li>
        <li class="nav-item px-lg-3 btn btn-link" v-else @click='goTo("Login")'>
          <a class="nav-link n-item">
            Login
          </a>
      </li>
      <!-- <li class="nav-item px-lg-3 ">
          <a class="nav-link n-item" href="#">
            The Team
          </a>
      </li>  -->
      <li class="nav-item px-lg-3 ">
        <button class="btn btn-primary-outline btn-mode" @click='change'>
        <img v-if='$root.d' src="@/assets/sun-color.png" width="103" height="40" alt="light" >
        <img v-else src="@/assets/moon-black.png" width="103" height="40" alt="dark" >
      </button>
      </li>
  </ul>
  </div>
  
</nav>
  </div>
  <!-- <div class='mx-width-extra py-lg-5' :class='{"bg-light":$root.d,"bg-dark":!$root.d}'>
    <div class="position-ralative">
      <button class="btn btn-link position-absolute d-none d-lg-block"
      @click='goTo("How")'
      :class='{"text-primary":$root.d,"text-white":!$root.d}'
       style='right:11%;'> <h3>How It Works</h3></button>

       <button class="btn btn-link position-absolute d-none d-lg-block"
      @click='goTo("Login")'
      :class='{"text-primary":$root.d,"text-white":!$root.d}'
       style='right:25%;'> <h3>Login</h3></button>

      <button class="position-absolute btn btn-primary-outline btn-mode" @click='change'>
        <img v-if='$root.d' src="@/assets/sun-color.png" width="103" height="40" alt="light">
        <img v-else src="@/assets/moon-black.png" width="103" height="40" alt="dark">
      </button>
    </div>
      <img v-if='$root.d' @click='goTo("Main")' src="@/assets/logo.png" class='link logo-image d-none d-lg-block' alt="" srcset="">
      <img v-if='$root.d' @click='goTo("Main")' src="@/assets/logo.png" class='link d-lg-none' 
      style='width: 50%;min-width:200px;
    margin: 8px 0px 0px 5px;' alt="" srcset="">

    <img v-if='!$root.d' @click='goTo("Main")' src="@/assets/logo-dark.png" class='link logo-image d-none d-lg-block' alt="" srcset="">
      <img v-if='!$root.d' @click='goTo("Main")' src="@/assets/logo-dark.png" class='link d-lg-none' style='width: 50%;min-width:200px;
    margin: 8px 0px 0px 5px;' alt="" srcset="">
    <button class="btn btn-link d-lg-none d-block"
      @click='goTo("How")'
      :class='{"text-primary":$root.d,"text-white":!$root.d}'
       > <span>How It Works</span></button>

  </div> -->
</template>

<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            toggled: false
        }
    },
    computed:{
      ...mapState(["user"]),  
      route() {
            return this.$route.name
        }
    },
    mounted(){
      this.$store.dispatch("checkUser")
    },
    methods:{
      change(){
        this.$root.d = !this.$root.d
        console.log("change detected, updaing S value as", this.$root.d)
        localStorage.setItem("claimelly-dark", this.$root.d)
      },
      goTo(name){
        this.$router.push({name})
      }
    }
}
</script>

<style scoped>

.navbar-nav {
            margin-left: auto;
        }
        .nav-shadow {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 81px 0px rgba(0, 0, 0, 0.2);
}
.SS_MM_AWM_Ltd_ {
  font-family:Arial;
  /* font-family: "BaskOldFace"; */
  color: rgb(60, 83, 88);
  line-height: 1.062;
  text-align: left;
  letter-spacing: 2px;
}
.n-item {
  font-family:Arial;
  color: rgb(104, 128, 133);
  line-height: 27px;
  font-size: 1rem;
  text-align: left;
}
 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: rgb(84, 116, 123);
} 
</style>