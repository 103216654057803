import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
const TK = localStorage.getItem('ClaimellyToken')
axios.defaults.headers.common['Authorization'] = (TK) ? TK : 'eyJhbGciOiJIUzI1NiJ9.ZnVzZS5nb2xkLUFwcA.IWwdTWsFFJJj87KBFwodDfbKF2Hk9hqhn2C7JtFAwzM';
Vue.use(Vuex)
import BYTECODE  from '../contract_sol_MerkleDistributor-bin.js';
import ABI  from '../contract_sol_MerkleDistributor.js'
import Web3 from 'web3';
const web3 = new Web3(Web3.givenProvider);
export default new Vuex.Store({
  state: {
    provider:null,
    apps:[],
    cStep:null,
    selectedAccount:null,
    txProgress:false,
    txHash:"",
    startTime:null,
    endTime:null,
    CONTRACT_ADDRESS:null,
    DECIMALS:0,
    setupComplete:false,
    selectedChain:null,
    currentStep:null,
    availableTokens:[],
    claimAccountNativeBalance:0,
    cAddress:null,
    owner: null,
    admin: null,
    symbol:null,
    decimals:0,
    accounts:[],
    claimData:null,
    claimAccountBalance:null,
    TokenBalance:0,
    isClaimDone:false,
    TokenName:null,
    claimable:false,
    tName:null,
    userAccount:null,
    validationError:null,
    userProjects:null,
    services:[],


    isLoading:false,
    action:"signin",
    user: null,
    URL:"http://localhost:3030",
    // URL:"https://dev.claimelly.com",
  },
  mutations: {
  },
  actions: {
    getPublished: async(context, data) => {
      return await axios.post(context.state.URL+'/api/publish/app', {app: data.app, v: data.v})
      .then((res) => { return res.data})
      .catch((err) => { console.log(err);  return {status: 0}})
    },
    
    addService: async(context, data) => {
      return await axios.post(context.state.URL+'/api/add/service', {service: data.service})
      .then((res) => { return res.data })
      .catch((err) => { console.log(err);return {status: 0} })
    },
    publishApp: async(context, data) => {
      axios.post(context.state.URL+'/api/update/app/status', data)
      .then((res) => { context.dispatch("getApps") })
    },
    getApps: async(context) => {
      axios.get(context.state.URL+'/api/get/apps/'+context.state.user._id)
      .then((res) => { 
        context.state.apps = res.data.apps
        context.state.services = res.data.services
      })
    },
    createApp: async(context, data) => {
      return await axios.post(context.state.URL+'/api/create-app', data)
      .then((res) => {return res.data})
      .catch((error) => {return {status: 0}})
    },
    async checkUser(context){
      let user = localStorage.getItem("ClaimellyUser");
      if (user) {
        context.state.user = JSON.parse(user)
      }
    },
    registerClient: async(context, user) => {
      return await axios.post(context.state.URL+'/api/register', user)
      .then((res) => {return res.data})
      .catch((error) => {return {status: 0, message: error}})
  },
  logout: (context) => {
    context.state.user = null;
                    localStorage.removeItem('ClaimellyUser')
                    localStorage.removeItem('ClaimellyToken');
  },
  login : async (context, data) => {
    return await axios.post(context.state.URL+'/api/login', {username: data.username, password: data.password})
    .then((res) => { 
        console.log(res);
        // return 0;
        if(res.data.status){
            if(res.data.user.emailVerified){
                if(res.data.user.twoFaStatus === "Running"){
                    return {status: 3, user: res.data.user};
                }else{
                    // context.state.userType = res.data.user.type;
                    context.state.user = res.data.user;
                    localStorage.setItem('ClaimellyUser', JSON.stringify(res.data.user))
                    localStorage.setItem('ClaimellyToken', res.data.token)
                    return {status: 1};
                }
                
            }
            else{
                return {status: 2, user: res.data.user};
            }
            

        }else{
            return {status: 0, message: res.data.message}
        }
    })
  },
  setProjects: async(context, wallet) => {
    // console.log("in set projects",context.state.registeredWallets[context.state.selectedAccount],context.state.registeredWallets[wallet])
    context.state.userProjects =  (context.state.registeredWallets[context.state.selectedAccount]) ? context.state.registeredWallets[context.state.selectedAccount] : null 
  },
  stepBack: async (context, data) => {
    context.state.currentStep = data.step
    context.state.registeredWallets[context.state.selectedAccount].claims[data.id].currentStep = data.step
    if (data.step == 1) {
    context.state.registeredWallets[context.state.selectedAccount].claims[data.id].merkleData = null
    }
    
    context.dispatch("updateAccounts")
  },
  resetApp: async (context, id) => {
    context.state.registeredWallets[context.state.selectedAccount].claims[id] = {
      currentStep:0,
      selectedChain:null,
      tokenAddress:null,
      DECIMALS:null,
      symbol:null,
      startTime:null,
      endTime:null,
      merkleData:null,
      contract:null,
      setupComplete:false,
      CONTRACT:null,
      CONTRACT_ABI:null,
      AUTH_TOKEN:"0x3107f81e9d50a791718776a083eF529ED0b54252",
      TOKEN:null,
      owner:"0x3107f81e9d50a791718776a083eF529ED0b54252"
    }
    await context.dispatch("updateAccounts");
    location.reload();
  },
  setUtilToken: async(context) => {
      let dec = await web3.eth.getBalance(context.state.CONTRACT_ADDRESS)
    context.state.claimAccountNativeBalance = (dec/1000000000000000000)
    if (context.state.availableTokens) {
      let val = Web3.utils.toChecksumAddress( context.state.userAccount.TOKEN )
      context.state.availableTokens.forEach(element => {
        let address = Web3.utils.toChecksumAddress(element.currency.address)
        console.log("data check in setUtilToken:", address, val)
        if (address == val ) {
          context.state.claimAccountBalance = element.value
        }
      });
    }
  },
  loadAppConfig: async(context, params) => {
    return await axios.get(context.state.URL+'/api/get/app/'+params.id)
    .then((res) => {
      if (res.data.app) {
        let account = res.data.app;
        context.state.cStep = account.currentStep
        context.state.setupComplete = account.isPublished
        context.state.selectedChain = account.selectedChain
        context.state.DECIMALS = account.DECIMALS
        context.state.symbol = account.symbol
        context.state.startTime = account.startTime
        context.state.endTime = account.endTime
        context.state.userAccount = account
        if (account.merkleData) {
          context.state.claimData = JSON.parse(account.merkleData)
        }
        if (account.CONTRACT) {
          context.state.CONTRACT_ADDRESS = account.CONTRACT
        }
        return res.data
      }else{
        return res.data
      }
    })
    .catch((error) => { return {status: 0, message: "Failed to Load the App"}})
    
  },
  startApp: async(context, account) => {
    context.state.registeredWallets[context.state.selectedAccount].claims[account].setupComplete = true
    context.dispatch("updateAccounts")
    location.reload()
  },
  deployContract: async(context, data) => {
    console.log(context.state.selectedAccount, data.account);
            // const Web3 = require('web3');
      const EthereumTx = require('ethereumjs-tx').Transaction;
      await web3.eth.setProvider(Web3.givenProvider);
      let dda = JSON.parse(data.app.merkleData );
      console.log(dda)

      // Data set up
      //Contract object and account info
      let deploy_contract = new web3.eth.Contract(ABI);
      // let account = '0xd935580Ce80986aD46D31e2dA55564Eb93A09318';


      // Function Parameter
      let payload = {
          data: BYTECODE,
          arguments:[
            data.app.TOKEN,
            // context.state.registeredWallets[context.state.selectedAccount].claims[data.id].TOKEN,
            dda.merkleRoot,
            data.app.owner
            // context.state.registeredWallets[context.state.selectedAccount].claims[data.id].owner
          ]
      }
      const estimatedGasPrice = await web3.eth.getGasPrice();

      // Calculate a higher gas price (e.g., 1.5 times the estimated gas price)
      const customGasPrice = Math.round(estimatedGasPrice * 1.5);
      let parameter = {
          from: data.account,
          gas: web3.utils.toHex(2700000),
          gasPrice: customGasPrice
      }
      // console.log(parameter, perameter)
      // Function Call
      context.state.txProgress = true
      deploy_contract.deploy(payload).send(parameter, (err, transactionHash) => {
          console.log('Transaction Hash :', transactionHash);
        data.app.TX_DATA = transactionHash
        context.state.txHash = transactionHash

      }).on('confirmation', () => {}).then(async (newContractInstance) => {
        console.log(newContractInstance)
        data.app.CONTRACT = newContractInstance.options.address
        // data.app.TX_DATA = JSON.stringify(newContractInstance)
        // context.state.registeredWallets[context.state.selectedAccount].claims[data.id].CONTRACT = newContractInstance.options.address
        data.app.currentStep = 3
        // context.state.registeredWallets[context.state.selectedAccount].claims[data.id].currentStep = 3
        let dec = await context.dispatch("updateAppData",{app: data.app, next: 3})
        console.log("response of updated app data", dec)
        context.state.txProgress = false
        location.reload()
          // console.log('Deployed Contract Address : ', newContractInstance.options.address);
      })  
    
  },
  updateProps: async(context, data) => {
    return await axios.post(context.state.URL+"/api/update/app-props/"+data.prop, data)
    .then((res) => { 
      return res.data
     })
     .catch((error) => { return {status: 0}})    
  },
  updateAppData: async (context, data) => {
    // context.state.registeredWallets[context.state.selectedAccount].claims[data.wallet].merkleData = data.data
    // context.state.registeredWallets[context.state.selectedAccount].claims[data.wallet].currentStep = 2
    return await axios.post(context.state.URL+"/api/update/app", data)
    .then((res) => { 
      if (res.data.status) {
        if(data.next){
          context.state.currentStep = data.next 
        }
        return res.data       
      }
     })
     .catch((error) => { return {status: 0}})
  },
  checkWalletAccount: async (context, wallet) => {
    if (context.state.registeredWallets[context.state.selectedAccount].claims[wallet]) {
      let account = context.state.registeredWallets[context.state.selectedAccount].claims[wallet];
      context.state.currentStep = account.currentStep
      context.state.selectedChain = account.selectedChain
      context.state.DECIMALS = account.DECIMALS
      context.state.symbol = account.symbol
      context.state.userAccount = account
      return true
    }else{
      return false;
    }
  },


  loadSymbol: async(context, id) => {
    context.state.DECIMALS = context.state.registeredWallets[context.state.selectedAccount].claims[id].DECIMALS
    context.state.symbol = context.state.registeredWallets[context.state.selectedAccount].claims[id].symbol
  },
  //functions ends, implemented for standalone plugin
  
  withdrawNative: async (context, data) => {
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    let  dta  = await Contract.methods.withdraw(
         ).encodeABI()
    console.log(dta)  
    const estimatedGasPrice = await web3.eth.getGasPrice();

    // Calculate a higher gas price (e.g., 1.5 times the estimated gas price)
    const customGasPrice = Math.round(estimatedGasPrice * 1.5);
    try {
      web3.eth.sendTransaction({
        from: data.sender,
        to: Contract.options.address,
        gas: '210000',
        gasPrice: customGasPrice,
        data:dta})
        .on('transactionHash', function(hash){
        })
        .on('receipt', async function(receipt){
          await context.dispatch("Bquery")
          await context.dispatch("setUtilToken")
          alert('Transaction submitted successfully')   

          // context.dispatch('BalanceCheck', account)
        })
        .on('error', (error) => {
          console.log("errir in claim", error)
        });
      return true
    } catch (error) {
      console.log(error);
      alert('error in submitting transaction')
      return false;
    }
  },
 
  updateAdmin: async (context, data) => {
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    try {
      let resp = await Contract.methods.setAdmin(data.wallet).send({from:data.sender});
      console.log(resp);
      alert('admin wallet updated')  
    } catch (error) {
      console.log(error);
      alert('failed to update admin wallet')
    }
  },
  getOwner: async (context) => {
   
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    try {
      await Contract.methods.owner().call().then((res) => {context.state.owner = res })
      
    } catch (error) {
      console.log("Error in getOwner:",error)
    }
  },
  getAdmin: async (context) => {
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    await Contract.methods.admin().call().then((res) => {context.state.admin = res })
  },
  withdraw: async(context, data) => {
    // console.log(data);
    // return;
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    let amt = (Number(data.amount) *10**context.state.DECIMALS).toString(16)
    amt = web3.utils.toBN("0x"+amt )
    console.log("amt is", amt)
    let  dta  = await Contract.methods.transferERC20(
      data.token ? data.token : context.state.userAccount.TOKEN,
      amt
         ).encodeABI()
    console.log(dta)  
    try {
        web3.eth.sendTransaction({
          from: data.sender,
          to: Contract.options.address,
          gas: '210000',
          data:dta
      })
      .on('transactionHash', function(hash){
      })
      .on('receipt', async function(receipt){
        alert('Transaction submitted successfully')    
        await context.dispatch('Bquery')
        await context.dispatch('setUtilToken')
      })
      .on('error', (error) => {
        console.log("errir in claim", error)
      });
    }catch(error){
      console.log('error of transaction',error)
      alert("transaction failed")
    }
    
  },
  withdrawFunds: async(context, data) => {
    console.log(data);
    // return;
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    try {
      let resp = await Contract.methods.transferERC20(
        data.token,
        String(web3.utils.toWei(data.amount)) )
        .send({from:data.sender});
      console.log(resp);
      alert('Admin Withdrawal Transaction submitted successfully')  
      return true
    } catch (error) {
      console.log(error);
      alert('error in submitting transaction')
      return false;
    }
    
  },
  submitTransaction: async (
    context,account 
    ) => {
      const Contract = await  new web3.eth.Contract(
        ABI,
        context.state.CONTRACT_ADDRESS
      );
  let  dta  = await Contract.methods.claim(
    context.state.claimData.claims[account]["index"],
     account,
      context.state.claimData.claims[account]["amount"],
       context.state.claimData.claims[account]["proof"]).encodeABI()
  console.log(dta)  
  const estimatedGasPrice = await web3.eth.getGasPrice();

      // Calculate a higher gas price (e.g., 1.5 times the estimated gas price)
      const customGasPrice = Math.round(estimatedGasPrice * 1.5);
  try {
      web3.eth.sendTransaction({
        from: account,
        to: Contract.options.address,
        gas: '210000',
        gasPrice: customGasPrice,
        data:dta
    })
    .on('transactionHash', function(hash){
    })
    .on('receipt', async function(receipt){
      alert('Transaction submitted successfully')    
      await context.dispatch('Bquery')
      await context.dispatch('setUtilToken')
    })
    .on('error', (error) => {
      console.log("errir in claim", error)
    });
  
    } catch (e) {
      alert("Error Occured :(")
      console.log(e)
      return e
    }

},
  Claim : async (context, account) => {
    
    try {
    await context.dispatch('submitTransaction', account)
    // context.state.TokenBalance += parseInt(context.state.claimData.claims[account]['amount'],16)/1000000000000000000;
    } catch (e) {
       alert("Your Address is Not In This Campaign");
       console.log('error', e);
       return e
     }
   
},
  hasClaimed : async (context, data) => {
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    console.log(data.account,context.state.claimData.claims)
    if(context.state.claimData.claims[data.account]){
    try {
    let claimCheck = await Contract.methods.isClaimed(context.state.claimData.claims[data.account]["index"]).call();
         if(!claimCheck) await context.dispatch("Claim", data.account); else alert("This Address Has Already Claimed "+data.value+" GT");
   
         } catch (e) {
           console.log(e)
           return e
         }
       }
   
    else {alert("Connect Metamask or Address not Found")}
   
   },
  claim: async function (context, account) {
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    const transactionParameters = {
      to: Contract.options.address, // Required except during contract publications.
      from: account, // must match user's active address.
      data: Contract.methods.claim(
        context.state.claimData.claims[account]["index"],
         account, 
         context.state.claimData.claims[account]["amount"],
          context.state.claimData.claims[account]["proof"])
          .encodeABI(),
    };
        console.log('transactions info',transactionParameters,context.state.claimData.claims[account]["amount"])
    try {
      await web3.eth.setProvider(Web3.givenProvider);

      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
        return txHash
        } catch (e) {
          alert("Error Occured :(")
          console.log(e)
          return e
        }
  },
  
  checkClaimStatus: async (context, address) => {
    const Contract = await  new web3.eth.Contract(
      ABI,
      context.state.CONTRACT_ADDRESS
    );
    let claimCheck = await Contract.methods.isClaimed(context.state.claimData.claims[address]["index"]).call()
    return claimCheck;
  },
  loadAccounts: async(context) => {
    console.log("i am loadAccounts")
    let accounts = localStorage.getItem("Claimelly-Accounts")
    if (accounts) {
      context.state.registeredWallets = JSON.parse(accounts)
      context.dispatch("setProjects")

    }
  },
  updateAccounts: async(context) => {
    let accounts = localStorage.setItem("Claimelly-Accounts", JSON.stringify(context.state.registeredWallets))
  },
  Bquery: async (context) => {
    let network = null;
    if (context.state.userAccount.selectedChain.chainId == "0x1") network = "ethereum"
    if (context.state.userAccount.selectedChain.chainId == "0x4") network = "ethereum"
    if (context.state.userAccount.selectedChain.chainId == "0x38") network = "bsc"
    if (context.state.userAccount.selectedChain.chainId == "0x61") network = "bsc_testnet"
    const query = `
{
  ethereum(network: ${network}) 
  {
    address(address: {is: "${context.state.CONTRACT_ADDRESS}"}) 
    {
      balances 
      {
        value
        currency 
        {
          symbol
          address
        }
      }
    }
  }
}
`;
const url = "https://graphql.bitquery.io/";
const opts = {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
    "X-API-KEY": "BQYzG41m6hqGVhipoCkbYr8kNdjQVGb8"
  },
  body: JSON.stringify({
      query
  })
};
try {
fetch(url, opts)
  .then(res => res.json())
  .then(async (res) => { 
    console.log("Bitquery Resp:", res)
    context.state.availableTokens = res.data.ethereum.address[0].balances
    await context.dispatch("setUtilToken")
   })
  .catch(console.error);  
} catch (error) {
console.log("Bitquery Error:", error)
}

  },
  BquerySymbolDecimals: async (context, data) => {
    console.log("running bquery function")
    let network = null;
    if (data.chain == "0x1") network = "ethereum"
    if (data.chain == "0x4") network = "ethereum"
    if (data.chain == "0x38") network = "bsc"
    if (data.chain == "0x61") network = "bsc_testnet"
    console.log("nerwork", network, "address", data.address)
    const query = ` {
      ethereum(network: ${network}){
        address(address: {is: "${data.address}"}){
          smartContract {
            currency {
              symbol
              decimals
            }
          }
        }
      }
    }`
let result = null
const url = "https://graphql.bitquery.io/";
const opts = {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
    "X-API-KEY": "BQYzG41m6hqGVhipoCkbYr8kNdjQVGb8"
  },
  body: JSON.stringify({
      query
  })
};
try {
await fetch(url, opts)
  .then(res => res.json())
  .then(async (res) => { 
    result = res.data.ethereum.address
    // context.state.availableTokens = res.data.ethereum.address[0].balances
    // await context.dispatch("setUtilToken")
   })
  .catch((error) => {
    console.log(error);
    result = false
  });  
} catch (error) {
console.log("Bitquery Error:", error)
result = false
}

return result

  }

},
  modules: {
  }
})
