<template>
  <div id="app" v-if='!isLoading'>
    <div id="popup1" class="overlay">
            <div class="popup">
                <h2 class='text-center'>Coming Soon</h2>
                <a class="close" href="#">&times;</a>
                
            </div>
        </div>
    <div id="nav mb-3">
      <Header />
    </div>
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  data(){
    return {
      isLoading:true
    }
  },
  components:{Header},
  mounted(){
    window.addEventListener('load', () => {
    this.isLoading = false
         
    })
    // this.$nextTick(() => {
    //     this.isLoading = false
    //   })
  },
  updated(){
  }
}
</script>

<style>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index:999999;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 400px;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: inherit;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}
</style>
